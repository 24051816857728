import Script from 'next/script';
import { useEffect, useState } from 'react';
import useStore from 'store';
import { Analytics, Gtm } from 'components/organisms';
import { Loading } from 'components/atoms';
import { getUserData } from 'utils/user';
import { init } from 'utils/cookies';
import { fetchFeatures, setFeaturesCache } from 'services/firebase';
import { useRouter } from 'next/router';
import * as gtag from 'utils/gtag';
import 'styles/globals.css';
import { CompanyProvider } from 'contexts';
import { SEO } from 'components/molecules';
import { formatTextRich } from 'utils/format';

interface IHostEnv {
  [env: string]: string;
}

function MyApp({ Component, pageProps }) {
  const {
    session: { signIn, isAuth, setLoading },
    featuresToggle: { setFeature, isEnabled },
  } = useStore();
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [cdnLoading, setCdnLoading] = useState<boolean>(true);
  const router = useRouter();

  const company = pageProps?.company;
  const vacancy = pageProps?.vacancy;
  const googleForJobsData = pageProps?.googleForJobsData;
  const BASE_URL = process.env.HOST;

  const handleAuthUser = async () => {
    setLoading(true);

    const user = await getUserData(isEnabled('applicantsV3'));
    user && signIn(user);

    setLoading(false);
  };

  useEffect(() => {
    init();
    Analytics();
    Gtm();

    const features = pageProps?.features;

    if (features) {
      setFeaturesCache(features);
      features.forEach(setFeature);
    }

    if (!isAuth) handleAuthUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleStart = () => setPageLoading(true);

    const handleComplete = () => setPageLoading(false);

    const handleRouteChange = (url: string) => {
      setPageLoading(false);
      gtag.pageview(url);
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleRouteChange);
    router.events.on('routeChangeError', handleComplete);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleRouteChange);
      router.events.off('routeChangeError', handleComplete);
    };
  }, [router.events]);

  const hosts: IHostEnv = {
    local: 'mfe-cdn-dev.solides.com',
    staging: 'mfe-cdn-stg.solides.com',
    release: 'hr.solides.com',
    production: 'hr.solides.com',
  };

  // const repositionSunChatProduction = () => {
  //   const sunContainer = document?.querySelector('div[id="chatbase-bubble-button"]');
  //   if (sunContainer) sunContainer.className = 'chat-container-override';
  // };

  // useEffect(() => {
  //   let interval: NodeJS.Timeout = setInterval(() => repositionSunChatProduction(), 1000);
  //   window.addEventListener('resize', repositionSunChatProduction);

  //   return () => {
  //     interval && clearInterval(interval);
  //     window.removeEventListener('resize', repositionSunChatProduction);
  //   };
  // }, []);

  const env: string = process.env.ENV || 'production';

  if (!company && !vacancy) {
    return <Component {...pageProps} />;
  }

  return (
    <>
      <Script
        src="https://cdn.tailwindcss.com"
        onLoad={() => {
          console.log('Script has loaded');
          setCdnLoading(false);
        }}
      />
      <Script src={`https://${hosts[env]}/styles/tailwind.js`} />
      <script id="chatbot-init" src="/assets/js/chatbot/init.js" />
      {vacancy ? (
        <SEO
          tabName={`Vaga ${vacancy.title}`}
          title={`Veja os detalhes da vaga ${vacancy.title} na empresa ${vacancy.companyName}`}
          description={formatTextRich(vacancy.description).join('. ').slice(0, 120)}
          keywords={`${vacancy.title}, Vaga de Emprego, empregos, vagas na ${company.name}, trabalho na ${company.name}, carreiras, oportunidades, oportunidades de trabalho, recrutamento`}
          baseUrl={vacancy.isHiddenJob ? process.env.HOST : `https://${company.slug}.solides.com.br`}
          image={vacancy.companyLogo || `https://${company.slug}.solides.com.br/logo-solides.svg`}
          googleForJobsData={googleForJobsData || ''}
          canonical={`https://${company.slug}.${process.env.JOBS_HOST}/vaga/${vacancy.id}?origem=portal`}
        />
      ) : (
        <SEO
          tabName={`Vagas de Emprego na ${company.name}`}
          title={`Vagas de Emprego na ${company.name} na Lista de vagas`}
          keywords={`vagas de emprego, empregos, vagas na ${company.name}, trabalho na ${company.name}, carreiras, oportunidades, oportunidades de trabalho, recrutamento`}
          description={`Busque vagas de emprego na ${company.name}. Encontre as melhores oportunidades e inicie sua carreira em uma empresa inovadora e de referência no mercado. Aproveite essa chance e faça parte do nosso time!`}
          baseUrl={BASE_URL}
          image={company.logo || `https://${company.slug}.vagas.solides.com.br/logo-solides.svg`}
          googleForJobsData={googleForJobsData || ''}
          canonical={`https://${company.slug}.${process.env.JOBS_HOST}`}
        />
      )}

      {pageLoading || cdnLoading ? (
        <Loading />
      ) : (
        <CompanyProvider slug={pageProps?.vacancy?.slug}>
          <Component {...pageProps} />
        </CompanyProvider>
      )}
    </>
  );
}

MyApp.getInitialProps = async () => {
  try {
    const features = await fetchFeatures(process.browser);

    useStore.setState(state => ({
      ...state,
      featuresToggle: {
        ...state.featuresToggle,
        features,
      },
    }));
    return { pageProps: { features } };
  } catch (err) {
    console.log(err);
  }

  return { pageProps: { features: null } };
};

export default MyApp;
